<template>
  <div>
    <v-list
      max-height="1000"
      class="overflow-y-auto"
      fill-height
      style="padding-top: 0px;padding-bottom: 0px;"
      dense
    >
      <template v-for="booking in bookings">
        <div :key="booking.uuid">
          <v-list-item
            @click="clickBooking(booking)"
            dense
          >
            <v-list-item-avatar
              color="primary"
              class="white--text justify-center"
              style="font-size: 20px;"
            >
              {{ booking.quantity }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ customerName(booking) }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ booking.item.title }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content
              v-if="booking.startsAtTime !== null && booking.startsAtTime !== undefined && booking.startsAtTime !== 0"
            >
              <v-list-item-title>
                {{ $t('arrival:') }} {{ dateParse(booking.startsAtTime).toISOString().replace('T', ' ').substr(0, 19) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="booking.uuid" />
        </div>
      </template>
    </v-list>
  </div>
</template>

<script>

export default {
	name: 'EventItemBookingList',
    props: {
      bookings: {
        type: Array, 
        default: () => []
      }
  },
  components: {
  },
  data: () => ({
		filtering: false,
        selectedBookings: null
  }),
    computed: {
        bookingQuantity() {
          let quantity = 0

          for(let i in this.bookings) {
            const booking = this.bookings[i]
            const bookingQuantity = booking.quantity || 1
            quantity = quantity + bookingQuantity
          }

          return quantity
        },
        disableCancelAll() {
          return this.bookings.every(booking => booking.status === '0' || booking.redeemedAt)
        },
        someCancelled() {
            return this.bookings.some(booking => booking.status === '0')
        },
    },
	methods: {
        dateParse(secondString) {
            if(secondString.toString().length === 10) {
                return new Date(secondString * 1000)
            } 
            else {
                return new Date(secondString)
            }
        },
        clickBooking(booking){
            this.selectedBookings = booking
        },
        countBooking(booking){
            return booking.length
        },
        customerName(booking) {
          if(typeof booking !== 'undefined' && booking !== null) {
            if(typeof booking.customer !== 'undefined' && booking.customer !== null) {
              if(typeof booking.customer.address !== 'undefined' && booking.customer.address !== null) {
                if(typeof booking.customer.address.name_line !== 'undefined' && booking.customer.address.name_line !== null) {
                  return booking.customer.address.name_line
                }
              }
            }
          }
          return this.$t('noName')
        },
	}
}
</script>