<template>
  <div>
    <v-dialog
      max-width="1250"
      :value="true"
      @keydown.esc="close"
      @click:outside="close"
      scrollable
    >
      <v-card
        tile
      >
        <v-card-title>
          {{ $t('bookings') }}
        </v-card-title>
        <v-card-subtitle
          dense 
        >
          {{ $t('totalBookings:') }} {{ totalBooking }}
        </v-card-subtitle>
        <v-card-text class="px-0">
          <v-list v-if="bookings === null || isLoading">
            <template v-for="index in 10">
              <v-skeleton-loader
                ref="skeleton"
                type="list-item-avatar-two-line"
                class="mx-auto"
                :key="index"
              />
            </template>
          </v-list>
          <EventItemBookingList
            :bookings="bookings"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="exportBookings"
            block
            large
          >
            {{ $t('export') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ExportObjectsDialog 
      @closed="closeExportDialog"
      v-if="exportObjects !== null && exportObjects.length > 0"
    />
  </div>
</template>

<script>
import EventItemBookingList from './EventItemBookingList'
import ExportObjectsDialog from '../ExportObjectsDialog'

export default {
  name: 'BookingDialog',
  props: {
    item: {
        type: Object, 
        default: () => {}
    }
  },
  components: {
    EventItemBookingList,
    ExportObjectsDialog
  },
  data() {
    return {
      dialog: true,
      isLoading: true,
      bookings: [],
      showExportDialog: false
    }
  },
  mounted() {
      this.$store.dispatch('getBookingsByItem', this.item.uuid).then(result => {
        if(Array.isArray(result)) {
          this.bookings = this.sortBookingsByStartTime(result)
        }
      }).finally(() => {
        this.isLoading = false
      })
  },
  
  computed: {
    exportObjects() {
      return this.$store.state.exportObjects
    },
    totalBookings() {
      if(Array.isArray(this.bookings)) {
        return this.bookings.reduce((sum, booking) => {
          if(typeof booking !== 'undefined' && booking !== null) {
            if(typeof booking.quantity !== 'undefined' && booking.quantity !== null) {
              return sum + booking.quantity
            }
            return sum + 1
          }
          return sum
        }, 0)
      }
      return 0
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    rebook(booking) {
      this.$emit('rebook', booking)
    },
    sortBookingsByStartTime(bookings){
        const SortedBookings = bookings.sort((a, b) => b.startsAtTime - a.startsAtTime)
        return SortedBookings
    },
    exportBookings() {
        this.showExportDialog= true
        this.$store.commit('updateExportObjects', this.bookings)
		},
    closeExportDialog() {
        this.$store.commit('updateExportObjects', null)
    }
  },
}
</script>
